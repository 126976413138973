export default {
  blank: () => import("/Users/david/Desktop/project/jwj_vue/layouts/blank.vue").then(m => m.default || m),
  default: () => import("/Users/david/Desktop/project/jwj_vue/layouts/default.vue").then(m => m.default || m),
  "default-backup": () => import("/Users/david/Desktop/project/jwj_vue/layouts/defaultBackup.vue").then(m => m.default || m),
  diary: () => import("/Users/david/Desktop/project/jwj_vue/layouts/diary.vue").then(m => m.default || m),
  "more-bak-20240310": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240310.vue").then(m => m.default || m),
  "more-bak-20240713": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.20240713.vue").then(m => m.default || m),
  "more-bak": () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.bak.vue").then(m => m.default || m),
  more: () => import("/Users/david/Desktop/project/jwj_vue/layouts/more.vue").then(m => m.default || m),
  room: () => import("/Users/david/Desktop/project/jwj_vue/layouts/room.vue").then(m => m.default || m),
  "today-section": () => import("/Users/david/Desktop/project/jwj_vue/layouts/todaySection.vue").then(m => m.default || m),
  write: () => import("/Users/david/Desktop/project/jwj_vue/layouts/write.vue").then(m => m.default || m)
}