import { default as _91id_93WHtXhLcrwdMeta } from "/Users/david/Desktop/project/jwj_vue/pages/[section]/[id].vue?macro=true";
import { default as indexDJcTzLf7oMMeta } from "/Users/david/Desktop/project/jwj_vue/pages/backup/index.vue?macro=true";
import { default as indexAkrYwaq5DLMeta } from "/Users/david/Desktop/project/jwj_vue/pages/backup/reset-password/index.vue?macro=true";
import { default as indexUtRnfwEulgMeta } from "/Users/david/Desktop/project/jwj_vue/pages/backup/signup/index.vue?macro=true";
import { default as indexeeBvAajVN6Meta } from "/Users/david/Desktop/project/jwj_vue/pages/bookmark/index.vue?macro=true";
import { default as _91bMCd_93B6Ko3ZlmSMMeta } from "/Users/david/Desktop/project/jwj_vue/pages/bookmark/view/[bMCd].vue?macro=true";
import { default as indexDU4bUrFnDxMeta } from "/Users/david/Desktop/project/jwj_vue/pages/column/index.vue?macro=true";
import { default as indexxoNhs6NEelMeta } from "/Users/david/Desktop/project/jwj_vue/pages/column/write/index.vue?macro=true";
import { default as indexVnedef4YN6Meta } from "/Users/david/Desktop/project/jwj_vue/pages/dailyNote/index.vue?macro=true";
import { default as indexYoXe0o0aAHMeta } from "/Users/david/Desktop/project/jwj_vue/pages/diary/[diaryCd]/index.vue?macro=true";
import { default as index_workdJyIrSUJvPMeta } from "/Users/david/Desktop/project/jwj_vue/pages/diary/index_work.vue?macro=true";
import { default as indexxsSIQ8HmGDMeta } from "/Users/david/Desktop/project/jwj_vue/pages/diary/index.vue?macro=true";
import { default as indexiaKKmxr9scMeta } from "/Users/david/Desktop/project/jwj_vue/pages/edit/index.vue?macro=true";
import { default as indexk8lYUHL6YnMeta } from "/Users/david/Desktop/project/jwj_vue/pages/editor/index.vue?macro=true";
import { default as indexJVNb0cFtX3Meta } from "/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/[postingCd]/index.vue?macro=true";
import { default as indexBzDs7ixYSdMeta } from "/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/index.vue?macro=true";
import { default as writeeWElDd9FyEMeta } from "/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/write.vue?macro=true";
import { default as index_32copyzI8dlewMVhMeta } from "/Users/david/Desktop/project/jwj_vue/pages/join/index copy.vue?macro=true";
import { default as indexCewreVS60pMeta } from "/Users/david/Desktop/project/jwj_vue/pages/join/index.vue?macro=true";
import { default as index0IXQXTqeZ4Meta } from "/Users/david/Desktop/project/jwj_vue/pages/join/welcome/index.vue?macro=true";
import { default as alarm_46oldBUAxWSqavMMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/alarm.old.vue?macro=true";
import { default as alarmi3cjbHBnG6Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/alarm.vue?macro=true";
import { default as indexhJHeasJgfJMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/fellow/[fellowCd]/index.vue?macro=true";
import { default as indexNdf5wEmp3dMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/fellow/index.vue?macro=true";
import { default as findMyGroupLZfzuf5ZEnMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/findMyGroup.vue?macro=true";
import { default as indexsgki4S7KlGMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/index.vue?macro=true";
import { default as noticegsd5HE5Q79Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/notice.vue?macro=true";
import { default as indexnj2PwcRk0oMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/offering/[grpCd]/index.vue?macro=true";
import { default as indexMzR1GEFIf4Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/[kind]/[id]/index.vue?macro=true";
import { default as indexpHavhyguwlMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/[kind]/index.vue?macro=true";
import { default as index5S9qiwf5nvMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/index.vue?macro=true";
import { default as indexaPu6u4TzmpMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sermon/[grpCd]/index.vue?macro=true";
import { default as accounttoqV8xSxuEMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/account.vue?macro=true";
import { default as barnabazPCx8B24L3Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/barnaba.vue?macro=true";
import { default as diaryAlarmSDzKt9PAWHMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/diaryAlarm.vue?macro=true";
import { default as indexPkS4emwx0QMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/index.vue?macro=true";
import { default as languageCh8VyiG1C7Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/language.vue?macro=true";
import { default as roomAlarmAjMSxuXBb5Meta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/roomAlarm.vue?macro=true";
import { default as userInfovVUHcrJdtQMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/setting/userInfo.vue?macro=true";
import { default as howToUseTTtNb1Kn0CMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/howToUse.vue?macro=true";
import { default as indexpdfgFXqMleMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/index.vue?macro=true";
import { default as subscribeaYxn1EcvnMMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/subscribe.vue?macro=true";
import { default as indexxl2bZXxVmAMeta } from "/Users/david/Desktop/project/jwj_vue/pages/more/weekly/[grpCd]/index.vue?macro=true";
import { default as joincvUGmFmS3TMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/auth/join.vue?macro=true";
import { default as login8z7ixvaP01Meta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/auth/login.vue?macro=true";
import { default as welcomeXT4cXKvlDEMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/auth/welcome.vue?macro=true";
import { default as bookmarkU8wpOhpAfHMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/diary/bookmark.vue?macro=true";
import { default as indexccDcRnXTcwMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/diary/index.vue?macro=true";
import { default as indexbomrnCahrrMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/index.vue?macro=true";
import { default as accountMOmedFGogeMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/account.vue?macro=true";
import { default as barnabaWuyO7oowcnMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/barnaba.vue?macro=true";
import { default as diaryAlarmM1SVeNyGPpMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/diaryAlarm.vue?macro=true";
import { default as fellowG09nLbKa2rMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/fellow.vue?macro=true";
import { default as fontSetting7fUDquBMoNMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/fontSetting.vue?macro=true";
import { default as howToUseDXdUZ31n9gMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/howToUse.vue?macro=true";
import { default as index_backup9zQVa3aE1xMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/index_backup.vue?macro=true";
import { default as indexbxRoZDoZ1BMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/index.vue?macro=true";
import { default as languageCV3Fkjm8GxMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/language.vue?macro=true";
import { default as mngVNFIMQiv61Meta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/mng.vue?macro=true";
import { default as noticeMzOGiq9ZupMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/notice.vue?macro=true";
import { default as offeringU8BM5hgwYLMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/offering.vue?macro=true";
import { default as pushNotiVBStxEC3ptMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/pushNoti.vue?macro=true";
import { default as roomAlarmKKq3HBJUEDMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/roomAlarm.vue?macro=true";
import { default as sermonGtr0kNC95qMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/sermon.vue?macro=true";
import { default as settingBTtbf2ShCJMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/setting.vue?macro=true";
import { default as sponsorccldqSF5KUMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/sponsor.vue?macro=true";
import { default as subscribeYpOsQJStq1Meta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/subscribe.vue?macro=true";
import { default as thursdays5lbhVWoIvMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/thursday.vue?macro=true";
import { default as userInfoGAHxTQWFjPMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/userInfo.vue?macro=true";
import { default as weeklykGAVNtUH6CMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/more/weekly.vue?macro=true";
import { default as find4WVQGm2ZcXMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/find.vue?macro=true";
import { default as FoldTestP8tJ2UZgMdMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/FoldTest.vue?macro=true";
import { default as index_backup0thpx7NaRWMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/index_backup.vue?macro=true";
import { default as index_backup2bEt3Fo7vwNMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/index_backup2.vue?macro=true";
import { default as indexjPBHuVlfGEMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/index.vue?macro=true";
import { default as listA8vIaeAy3OMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/list.vue?macro=true";
import { default as make5ubyuy0t7kMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/make.vue?macro=true";
import { default as viewbiypOT99tcMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/room/view.vue?macro=true";
import { default as cardsaUgin9nzJzMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/cards.vue?macro=true";
import { default as columnZtdEuv2I9hMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/column.vue?macro=true";
import { default as diaryiukCvLg2NZMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/diary.vue?macro=true";
import { default as diaryViewxym3VSDAKhMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/diaryView.vue?macro=true";
import { default as index4bWzHrdursMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/index.vue?macro=true";
import { default as moment1qrsylmDR9Meta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/today/moment.vue?macro=true";
import { default as indexFFmybRK0wbMeta } from "/Users/david/Desktop/project/jwj_vue/pages/pub/write/index.vue?macro=true";
import { default as indexOqa3qMRt2sMeta } from "/Users/david/Desktop/project/jwj_vue/pages/rcmd/index.vue?macro=true";
import { default as indexDxvl6Nw95rMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/[roomCd]/diaryCd/[diaryCd]/index.vue?macro=true";
import { default as indexLbhWg0Gh6MMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/[roomCd]/index.vue?macro=true";
import { default as findSwLowxnMIyMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/find.vue?macro=true";
import { default as indexiiZPL4RbgHMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/index.vue?macro=true";
import { default as list2wPjFTMNPNMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/list.vue?macro=true";
import { default as makeqf8G5hoCYxMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/make.vue?macro=true";
import { default as viewsLXBslzmKOMeta } from "/Users/david/Desktop/project/jwj_vue/pages/room/view.vue?macro=true";
import { default as indexul3tgeQc5iMeta } from "/Users/david/Desktop/project/jwj_vue/pages/signin/index.vue?macro=true";
import { default as indexJWI5lsLCPZMeta } from "/Users/david/Desktop/project/jwj_vue/pages/today/index.vue?macro=true";
import { default as indexOwbD0YVm6QMeta } from "/Users/david/Desktop/project/jwj_vue/pages/withTodayStory/index.vue?macro=true";
import { default as index3wp73pPHw1Meta } from "/Users/david/Desktop/project/jwj_vue/pages/write/index.vue?macro=true";
import { default as _91id_93v0EBFA6aQdMeta } from "~/pages/[section]/[id].vue?macro=true";
import { default as component_45stub4NgSMIAP8tMeta } from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub4NgSMIAP8t } from "/Users/david/Desktop/project/jwj_vue/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "section-id",
    path: "/:section()/:id()",
    meta: _91id_93WHtXhLcrwdMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/[section]/[id].vue").then(m => m.default || m)
  },
  {
    name: "backup",
    path: "/backup",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/backup/index.vue").then(m => m.default || m)
  },
  {
    name: "backup-reset-password",
    path: "/backup/reset-password",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/backup/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "backup-signup",
    path: "/backup/signup",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/backup/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "bookmark",
    path: "/bookmark",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/bookmark/index.vue").then(m => m.default || m)
  },
  {
    name: "bookmark-view-bMCd",
    path: "/bookmark/view/:bMCd()",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/bookmark/view/[bMCd].vue").then(m => m.default || m)
  },
  {
    name: "column",
    path: "/column",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/column/index.vue").then(m => m.default || m)
  },
  {
    name: "ColumnWrite",
    path: "/column/write",
    meta: indexxoNhs6NEelMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/column/write/index.vue").then(m => m.default || m)
  },
  {
    name: "dailyNote",
    path: "/dailyNote",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/dailyNote/index.vue").then(m => m.default || m)
  },
  {
    name: "diary-diaryCd",
    path: "/diary/:diaryCd()",
    meta: indexYoXe0o0aAHMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/diary/[diaryCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "diary-index_work",
    path: "/diary/index_work",
    meta: index_workdJyIrSUJvPMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/diary/index_work.vue").then(m => m.default || m)
  },
  {
    name: "diary",
    path: "/diary",
    meta: indexxsSIQ8HmGDMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/diary/index.vue").then(m => m.default || m)
  },
  {
    name: "edit",
    path: "/edit",
    meta: indexiaKKmxr9scMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "editor",
    path: "/editor",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/editor/index.vue").then(m => m.default || m)
  },
  {
    name: "groupPosting-grpCd-postingCd",
    path: "/groupPosting/:grpCd()/:postingCd()",
    meta: indexJVNb0cFtX3Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/[postingCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "groupPosting-grpCd",
    path: "/groupPosting/:grpCd()",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "groupPosting-grpCd-write",
    path: "/groupPosting/:grpCd()/write",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/groupPosting/[grpCd]/write.vue").then(m => m.default || m)
  },
  {
    name: "join-index copy",
    path: "/join/index%20copy",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/join/index copy.vue").then(m => m.default || m)
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/join/index.vue").then(m => m.default || m)
  },
  {
    name: "join-welcome",
    path: "/join/welcome",
    meta: index0IXQXTqeZ4Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/join/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "more-alarm.old",
    path: "/more/alarm.old",
    meta: alarm_46oldBUAxWSqavMMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/alarm.old.vue").then(m => m.default || m)
  },
  {
    name: "more-alarm",
    path: "/more/alarm",
    meta: alarmi3cjbHBnG6Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/alarm.vue").then(m => m.default || m)
  },
  {
    name: "more-fellow-fellowCd",
    path: "/more/fellow/:fellowCd()",
    meta: indexhJHeasJgfJMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/fellow/[fellowCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "more-fellow",
    path: "/more/fellow",
    meta: indexNdf5wEmp3dMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/fellow/index.vue").then(m => m.default || m)
  },
  {
    name: "more-findMyGroup",
    path: "/more/findMyGroup",
    meta: findMyGroupLZfzuf5ZEnMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/findMyGroup.vue").then(m => m.default || m)
  },
  {
    name: "more",
    path: "/more",
    meta: indexsgki4S7KlGMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/index.vue").then(m => m.default || m)
  },
  {
    name: "more-notice",
    path: "/more/notice",
    meta: noticegsd5HE5Q79Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/notice.vue").then(m => m.default || m)
  },
  {
    name: "more-offering-grpCd",
    path: "/more/offering/:grpCd()",
    meta: indexnj2PwcRk0oMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/offering/[grpCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "more-sermon_bak-kind-id",
    path: "/more/sermon_bak/:kind()/:id()",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/[kind]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "more-sermon_bak-kind",
    path: "/more/sermon_bak/:kind()",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/[kind]/index.vue").then(m => m.default || m)
  },
  {
    name: "more-sermon_bak",
    path: "/more/sermon_bak",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sermon_bak/index.vue").then(m => m.default || m)
  },
  {
    name: "more-sermon-grpCd",
    path: "/more/sermon/:grpCd()",
    meta: indexaPu6u4TzmpMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sermon/[grpCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-account",
    path: "/more/setting/account",
    meta: accounttoqV8xSxuEMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/account.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-barnaba",
    path: "/more/setting/barnaba",
    meta: barnabazPCx8B24L3Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/barnaba.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-diaryAlarm",
    path: "/more/setting/diaryAlarm",
    meta: diaryAlarmSDzKt9PAWHMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/diaryAlarm.vue").then(m => m.default || m)
  },
  {
    name: "more-setting",
    path: "/more/setting",
    meta: indexPkS4emwx0QMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/index.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-language",
    path: "/more/setting/language",
    meta: languageCh8VyiG1C7Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/language.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-roomAlarm",
    path: "/more/setting/roomAlarm",
    meta: roomAlarmAjMSxuXBb5Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/roomAlarm.vue").then(m => m.default || m)
  },
  {
    name: "more-setting-userInfo",
    path: "/more/setting/userInfo",
    meta: userInfovVUHcrJdtQMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/setting/userInfo.vue").then(m => m.default || m)
  },
  {
    name: "more-sponsor-howToUse",
    path: "/more/sponsor/howToUse",
    meta: howToUseTTtNb1Kn0CMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "more-sponsor",
    path: "/more/sponsor",
    meta: indexpdfgFXqMleMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/index.vue").then(m => m.default || m)
  },
  {
    name: "more-sponsor-subscribe",
    path: "/more/sponsor/subscribe",
    meta: subscribeaYxn1EcvnMMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/sponsor/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "more-weekly-grpCd",
    path: "/more/weekly/:grpCd()",
    meta: indexxl2bZXxVmAMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/more/weekly/[grpCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "pub-auth-join",
    path: "/pub/auth/join",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/auth/join.vue").then(m => m.default || m)
  },
  {
    name: "pub-auth-login",
    path: "/pub/auth/login",
    meta: login8z7ixvaP01Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "pub-auth-welcome",
    path: "/pub/auth/welcome",
    meta: welcomeXT4cXKvlDEMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/auth/welcome.vue").then(m => m.default || m)
  },
  {
    name: "pub-diary-bookmark",
    path: "/pub/diary/bookmark",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/diary/bookmark.vue").then(m => m.default || m)
  },
  {
    name: "pub-diary",
    path: "/pub/diary",
    meta: indexccDcRnXTcwMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/diary/index.vue").then(m => m.default || m)
  },
  {
    name: "pub",
    path: "/pub",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/index.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-account",
    path: "/pub/more/account",
    meta: accountMOmedFGogeMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/account.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-barnaba",
    path: "/pub/more/barnaba",
    meta: barnabaWuyO7oowcnMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/barnaba.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-diaryAlarm",
    path: "/pub/more/diaryAlarm",
    meta: diaryAlarmM1SVeNyGPpMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/diaryAlarm.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-fellow",
    path: "/pub/more/fellow",
    meta: fellowG09nLbKa2rMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/fellow.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-fontSetting",
    path: "/pub/more/fontSetting",
    meta: fontSetting7fUDquBMoNMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/fontSetting.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-howToUse",
    path: "/pub/more/howToUse",
    meta: howToUseDXdUZ31n9gMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/howToUse.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-index_backup",
    path: "/pub/more/index_backup",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/index_backup.vue").then(m => m.default || m)
  },
  {
    name: "pub-more",
    path: "/pub/more",
    meta: indexbxRoZDoZ1BMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/index.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-language",
    path: "/pub/more/language",
    meta: languageCV3Fkjm8GxMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/language.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-mng",
    path: "/pub/more/mng",
    meta: mngVNFIMQiv61Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/mng.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-notice",
    path: "/pub/more/notice",
    meta: noticeMzOGiq9ZupMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/notice.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-offering",
    path: "/pub/more/offering",
    meta: offeringU8BM5hgwYLMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/offering.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-pushNoti",
    path: "/pub/more/pushNoti",
    meta: pushNotiVBStxEC3ptMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/pushNoti.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-roomAlarm",
    path: "/pub/more/roomAlarm",
    meta: roomAlarmKKq3HBJUEDMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/roomAlarm.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-sermon",
    path: "/pub/more/sermon",
    meta: sermonGtr0kNC95qMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/sermon.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-setting",
    path: "/pub/more/setting",
    meta: settingBTtbf2ShCJMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/setting.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-sponsor",
    path: "/pub/more/sponsor",
    meta: sponsorccldqSF5KUMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/sponsor.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-subscribe",
    path: "/pub/more/subscribe",
    meta: subscribeYpOsQJStq1Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-thursday",
    path: "/pub/more/thursday",
    meta: thursdays5lbhVWoIvMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/thursday.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-userInfo",
    path: "/pub/more/userInfo",
    meta: userInfoGAHxTQWFjPMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/userInfo.vue").then(m => m.default || m)
  },
  {
    name: "pub-more-weekly",
    path: "/pub/more/weekly",
    meta: weeklykGAVNtUH6CMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/more/weekly.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-find",
    path: "/pub/room/find",
    meta: find4WVQGm2ZcXMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/find.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-FoldTest",
    path: "/pub/room/FoldTest",
    meta: FoldTestP8tJ2UZgMdMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/FoldTest.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-index_backup",
    path: "/pub/room/index_backup",
    meta: index_backup0thpx7NaRWMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/index_backup.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-index_backup2",
    path: "/pub/room/index_backup2",
    meta: index_backup2bEt3Fo7vwNMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/index_backup2.vue").then(m => m.default || m)
  },
  {
    name: "pub-room",
    path: "/pub/room",
    meta: indexjPBHuVlfGEMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/index.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-list",
    path: "/pub/room/list",
    meta: listA8vIaeAy3OMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/list.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-make",
    path: "/pub/room/make",
    meta: make5ubyuy0t7kMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/make.vue").then(m => m.default || m)
  },
  {
    name: "pub-room-view",
    path: "/pub/room/view",
    meta: viewbiypOT99tcMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/room/view.vue").then(m => m.default || m)
  },
  {
    name: "pub-today-cards",
    path: "/pub/today/cards",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/cards.vue").then(m => m.default || m)
  },
  {
    name: "pub-today-column",
    path: "/pub/today/column",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/column.vue").then(m => m.default || m)
  },
  {
    name: "pub-today-diary",
    path: "/pub/today/diary",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/diary.vue").then(m => m.default || m)
  },
  {
    name: "pub-today-diaryView",
    path: "/pub/today/diaryView",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/diaryView.vue").then(m => m.default || m)
  },
  {
    name: "pub-today",
    path: "/pub/today",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/index.vue").then(m => m.default || m)
  },
  {
    name: "pub-today-moment",
    path: "/pub/today/moment",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/today/moment.vue").then(m => m.default || m)
  },
  {
    name: "pub-write",
    path: "/pub/write",
    meta: indexFFmybRK0wbMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/pub/write/index.vue").then(m => m.default || m)
  },
  {
    name: "rcmd",
    path: "/rcmd",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/rcmd/index.vue").then(m => m.default || m)
  },
  {
    name: "room-roomCd-diaryCd-diaryCd",
    path: "/room/:roomCd()/diaryCd/:diaryCd()",
    meta: indexDxvl6Nw95rMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/[roomCd]/diaryCd/[diaryCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "room-roomCd",
    path: "/room/:roomCd()",
    meta: indexLbhWg0Gh6MMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/[roomCd]/index.vue").then(m => m.default || m)
  },
  {
    name: "room-find",
    path: "/room/find",
    meta: findSwLowxnMIyMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/find.vue").then(m => m.default || m)
  },
  {
    name: "room",
    path: "/room",
    meta: indexiiZPL4RbgHMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/index.vue").then(m => m.default || m)
  },
  {
    name: "room-list",
    path: "/room/list",
    meta: list2wPjFTMNPNMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/list.vue").then(m => m.default || m)
  },
  {
    name: "MakeRoom",
    path: "/room/make",
    meta: makeqf8G5hoCYxMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/make.vue").then(m => m.default || m)
  },
  {
    name: "room-view",
    path: "/room/view",
    meta: viewsLXBslzmKOMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/room/view.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: indexul3tgeQc5iMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: "Today",
    path: "/today",
    meta: indexJWI5lsLCPZMeta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/today/index.vue").then(m => m.default || m)
  },
  {
    name: "withTodayStory",
    path: "/withTodayStory",
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/withTodayStory/index.vue").then(m => m.default || m)
  },
  {
    name: "write",
    path: "/write",
    meta: index3wp73pPHw1Meta || {},
    component: () => import("/Users/david/Desktop/project/jwj_vue/pages/write/index.vue").then(m => m.default || m)
  },
  {
    name: "shareDetail",
    path: "/share/:roomCd/:diaryCd",
    meta: _91id_93v0EBFA6aQdMeta || {},
    component: () => import("~/pages/[section]/[id].vue").then(m => m.default || m)
  },
  {
    name: component_45stub4NgSMIAP8tMeta?.name,
    path: "/",
    component: component_45stub4NgSMIAP8t
  }
]